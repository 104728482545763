import {Component} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialogClose} from '@angular/material/dialog'

@Component({
  selector: 'spb-install-instructions-dialog',
  templateUrl: './install-instructions-dialog.component.html',
  imports: [
    MatDialogClose,
    MatButton
  ],
  styleUrls: ['./install-instructions-dialog.component.scss']
})
export class InstallInstructionsDialogComponent {

}
