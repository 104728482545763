import {DatePipe, SlicePipe} from '@angular/common'
import {Component, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {DataService} from '../../services/data.service'

type Translations = Record<string, string>;

@Component({
  selector: 'spb-id-list-dialog',
  templateUrl: './id-list-dialog.component.html',
  styleUrls: ['./id-list-dialog.component.scss'],
  imports: [
    MatFormField,
    MatDialogContent,
    MatDialogTitle,
    DatePipe,
    SlicePipe,
    MatInput,
    MatButton,
    MatDialogClose,
    MatLabel
  ]
})
export class IdListDialogComponent implements OnInit {
  public data: any

  constructor(
    private dataService: DataService
  ) {
  }

  public ngOnInit() {
    this.data = this.dataService.userData()
    this.translate()
  }

  private translate() {
    const translations: Translations = {
      'Invalid QR code': 'Felaktig QR kod',
      'QR code missing or empty': 'QR kod saknas eller är tom',
      'invalidParameters': 'Felaktiga parametrar',
      'verificationFailed': 'Misslyckad',
      'ID card verification not passed': 'Verifiering av ID-kort misslyckades'
    }

    if (Object.prototype.hasOwnProperty.call(translations, this.data.details)) {
      this.data.details = translations[this.data.details]
    }

    if (Object.prototype.hasOwnProperty.call(translations, this.data.errorCode)) {
      this.data.errorCode = translations[this.data.errorCode]
    }
  }
}
