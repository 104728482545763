{
  "name": "id-app",
  "version": "1.0.0",
  "scripts": {
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost.sparbanken.me",
    "watch": "ng test",
    "build": "ng build",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "style-lint": "stylelint  src/**/*.scss --fix",
    "test": "ng test --watch=false --code-coverage"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.0",
    "@angular/common": "^19.1.0",
    "@angular/compiler": "^19.1.0",
    "@angular/core": "^19.1.0",
    "@angular/forms": "^19.1.0",
    "@angular/platform-browser": "^19.1.0",
    "@angular/platform-browser-dynamic": "^19.1.0",
    "@angular/router": "^19.1.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.0",
    "@angular-eslint/builder": "19.0.2",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/schematics": "19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/cdk": "^19.0.5",
    "@angular/cli": "~19.1.0",
    "@angular/compiler-cli": "^19.1.0",
    "@angular/material": "^19.0.5",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^19.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^19.0.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^19.0.0",
    "@types/hammerjs": "^2.0.46",
    "@types/jasmine": "~5.1.5",
    "@types/sparbanken-syd-auth-backend": "^2.3.0",
    "@types/sparbanken-syd-bil": "^0.0.6",
    "angular-eslint": "^19.0.2",
    "eslint": "^9.18.0",
    "hammerjs": "^2.0.8",
    "jasmine-core": "~5.5.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.5.1",
    "qr-scanner": "^1.4.2",
    "stylelint": "^16.13.2",
    "stylelint-config-sass-guidelines": "^12.1.0",
    "stylelint-config-standard-scss": "^14.0.0",
    "typescript": "~5.6.3",
    "typescript-eslint": "^8.20.0"
  }
}
