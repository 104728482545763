import {Component} from '@angular/core'
import {MatDialogContent, MatDialogTitle} from '@angular/material/dialog'

@Component({
  selector: 'spb-wait-dialog',
  templateUrl: './wait-dialog.component.html',
  imports: [
    MatDialogTitle,
    MatDialogContent
  ]
})
export class WaitDialogComponent {

}
