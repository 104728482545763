import {ElementRef, InjectionToken} from '@angular/core'
import QrScanner from 'qr-scanner'

export interface IQrScannerFactory {
  getScanner(element: ElementRef, callback: (r: any) => void, options: any): QrScanner

  listCameras(requestLabels?: boolean): Promise<QrScanner.Camera[]>
}

export const QR_SCANNER_FACTORY = new InjectionToken<IQrScannerFactory>('qrScannerFactory')
