<div class="holder">
  <div class="button-holder">
    <div>Klicka på ikonen <span><img alt="share" height="20px"
                                     src="assets/ios-share.svg"></span> i adressfältet och
      välj ”Lägg till på hemskärmen” för att installera appen.
    </div>
    <img alt="arrow" class="arrow" src="assets/arrow.png">
  </div>
  <div>
    <button color="primary" mat-dialog-close mat-raised-button>Stäng</button>
  </div>
</div>
