<div (swiperight)="handleSwipeRight.emit()"
     class="holder"
>
  <div class="text-holder">
    <h3>Hjälp & Användarguide</h3>
    <p>Välkommen till användarguiden för vår tjänst för verifiering av digitalt
      ID-kort. Här följer en snabb genomgång
      av tjänstens funktioner som finns under våra fyra huvudflikar.
    </p>
    <p>Vi hoppas att denna guide ger dig en bra förståelse hur vår tjänsten
      fungerar. Om du har ytterligare frågor eller
      behöver mer hjälp är du välkommen att kontakta IT-support.
      Lycka till med dina skanningar och ha en trevlig arbetsdag!</p>


    <h3>Skanna</h3>
    <p>Under fliken ”skanna” kan du skanna kunders digitala ID-kort från BankID.
      Tryck på knappen för att börja skanna.
      Tjänsten visar om det skannade digitala ID-kortet är giltigt eller
      inte.</p>

    <h3>Utförda</h3>
    <p>Här kan du se en lista över dina tidigare skanningar. Varje post visar om
      skanningen var lyckad eller misslyckad,
      samt datum och tid för skanningen.</p>
    <p>För att se mer detaljer om en specifik skanning, klicka bara på posten i
      listan. Detta tar dig till en detaljsida
      där du kan se mer information om den utförda skanningen.</p>

    <h3>Installera</h3>
    <p>Om du vill få snabb tillgång till appen kan du installera den på din
      telefon. För att installera appen klickar du
      på menyn uppe till höger, väljer installera och följer
      instruktionerna.</p>
  </div>
</div>
