import {NgOptimizedImage} from '@angular/common'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  provideAppInitializer,
  provideZoneChangeDetection
} from '@angular/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {MatSelectModule} from '@angular/material/select'
import {MatTabsModule} from '@angular/material/tabs'
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerModule
} from '@angular/platform-browser'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {
  provideRouter,
  withHashLocation,
  withInMemoryScrolling
} from '@angular/router'
import {HammerConfig} from './1-start/start.component'
import {routes} from './app.routes'
import {LOCAL_STORAGE, WINDOW} from './application/app'
import {authInterceptor} from './application/auth.interceptor'

import {QrFactory} from './application/qr-factory.class'
import {QR_SCANNER_FACTORY} from './application/qr-scanner.provider'
import {responseInterceptor} from './application/response.interceptor'
import {ConfigService} from './services/config.service'

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule, MatTabsModule, NgOptimizedImage, HammerModule, MatSelectModule),
    {provide: QR_SCANNER_FACTORY, useClass: QrFactory},
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    provideAppInitializer(() => {
      const initializerFn = ((s: ConfigService) => () => s.bootstrap())(inject(ConfigService))
      return initializerFn()
    }),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'outline'}
    },
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes, withHashLocation(), withInMemoryScrolling({anchorScrolling: 'enabled'})),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        responseInterceptor
      ])
    ),
    {
      provide: LOCAL_STORAGE,
      useFactory: () => localStorage
    },
    {provide: WINDOW, useValue: window},
    provideAnimationsAsync()
  ]
}