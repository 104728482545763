<div matDialogTitle>{{ data.title }}</div>
<div matDialogContent>
  <mat-form-field style="padding-top: 6px">
    <mat-label>Tidpunkt:</mat-label>
    <input class="line-height" matInput readonly
           value="{{data.timeStamp | date: 'YYYY-MM-dd, HH:mm': undefined : 'fr'}}">
  </mat-form-field>

  <!--  IF Correct -->
  @if (data.user) {
    <mat-form-field style="padding-top: 6px">
      <mat-label>Personnummer:</mat-label>
      <input class="line-height" readonly matInput
             value="{{ data.user.personalNumber | slice:0:8 }}-{{ data.user.personalNumber | slice:8 }}">
    </mat-form-field>
    <mat-form-field style="padding-top: 6px">
      <mat-label>Namn:</mat-label>
      <input class="line-height" readonly matInput value="{{data.user.name}}">
    </mat-form-field>
    <mat-form-field style="padding-top: 6px">
      <mat-label>Ålder:</mat-label>
      <input class="line-height" readonly matInput value="{{data.user.age}}">
    </mat-form-field>
  }

  <!--  IF Error-->
  @if (data.errorCode) {
    <mat-form-field style="padding-top: 6px">
      <mat-label>Felmeddelande:</mat-label>
      <input class="line-height" readonly matInput value="{{data.errorCode}}">
    </mat-form-field>
  }

  @if (data.details) {
    <mat-form-field style="padding-top: 6px">
      <mat-label>Detaljer:</mat-label>
      <textarea rows="2" class="line-height" readonly matInput
                value="{{data.details}}"></textarea>
    </mat-form-field>
  }

  <button color="primary" mat-dialog-close="true" mat-raised-button>Stäng
  </button>

</div>

